import React from 'react';

export default function Logo({ className }) {
  return (
    <svg
      viewBox="0 0 258 146"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      className={className}
    >
      <path
        d="M61.275 42.396c0-4.167 0-7.667-.167-10.167-.166-2.5-.333-4.333-.833-5.666-.5-1.334-1.333-2.334-2.167-2.834-1-.5-2.333-.833-4.166-1l-5.167-.666c-.833-.5-1.167-1.334-1-2.667 0-1.5.5-2.5 1.333-3 5.5-.5 11.5-.833 18-1.167 6.334-.166 13.167-.333 20.5-.333 7.167 0 13 .333 18 1.167 4.667.833 9 1.833 12.5 3.166 2.5 1 5 2.334 7.5 3.834a27.864 27.864 0 016.5 6c1.834 2.333 3.5 5 4.667 8.166 1.167 3.334 1.667 6.834 1.667 10.834 0 4.5-.667 8.5-2 11.833-1.5 3.333-3.334 6.167-5.5 8.5a25.663 25.663 0 01-7.334 5.5c-2.833 1.5-5.5 2.5-8.166 3.333-2.667 1-5.167 1.5-7.5 1.667-2.5.333-4.334.5-5.834.5h-2.666c-.834 0-1.834 0-2.667-.167-.667-.5-1-1.166-1.167-2.166-.166-1 .167-1.834.667-2.334 3.5-1.166 6.333-2.5 8.5-4.333 2.167-1.667 3.833-3.667 5-6 1.167-2.167 2-4.667 2.5-7.333.5-2.834.667-5.667.667-8.834 0-3.166-.334-6.166-1.167-9.333-1-3-2.167-5.667-3.833-8.167-1.834-2.5-4-4.5-6.667-6s-5.833-2.166-9.667-2.166c-3.166 0-5 .5-5.5 1.666-.666 1.167-1 3.5-1 6.834v70.333c0 3.833.167 7 .334 9.167.166 2.166.5 4 1.166 5.166.5 1.167 1.334 2 2.5 2.5 1.167.5 2.667.834 4.5 1.167l5.334.833c.833.5 1.333 1.334 1.333 2.667 0 1.5-.5 2.5-1.333 3-4.667-.167-9.167-.333-13.167-.5H61.442c-4.167.167-8.5.333-13 .5-.834-.5-1.334-1.5-1.5-3-.167-1.333.333-2.167 1.166-2.667l4.667-.833c1.833-.333 3.333-.667 4.5-1.167 1.167-.5 2-1.333 2.667-2.5.5-1.166 1-3 1.166-5.166.167-2.167.167-5.334.167-9.167v-59z"
        fillRule="nonzero"
      />
      <path
        d="M33.703 117.978c0 5.5 1.5 9.5 4.666 12 3.334 2.5 7.834 3.833 14 4v11.333c-10.333 0-18.166-2.5-23.833-7-5.667-4.666-8.5-11.166-8.5-19.5V94.145c0-5.667-1.667-9.667-5.167-12-3.333-2.5-8.333-3.667-14.833-3.667v-11.5c7-.167 12.167-1.333 15.333-3.833 3-2.5 4.667-6.5 4.667-11.667V26.645c0-8.5 2.833-15 8.833-19.667 5.834-4.667 13.667-7 23.5-7V11.31c-12.5.334-18.666 5.667-18.666 16.167v24c0 11.667-6 18.667-18.167 20.667v1c12.167 2.166 18.167 9 18.167 20.666v24.167zM167.98 125.812l-27.733-72.8h20.8l14 41.467c1.6 5.333 2.533 10.267 2.933 14.933h.4c.267-4.133 1.2-9.2 2.934-14.933l14-41.466h20.8l-27.734 72.8h-20.4z"
        fillRule="nonzero"
      />
      <path
        d="M242.091 72.145c-12.167-2-18.167-9-18.167-20.667v-24c0-10.5-6.166-15.834-18.5-16.167V-.022c10 0 17.834 2.333 23.667 7.166 5.667 4.667 8.5 11.167 8.5 19.5v24.834c0 5.333 1.667 9.167 4.833 11.667 3.167 2.5 8.334 3.666 15.334 3.833v11.5c-6.667 0-11.667 1.167-15 3.667-3.5 2.333-5.167 6.333-5.167 12v24.666c0 8.334-2.833 14.834-8.333 19.334-5.5 4.666-13.5 7.166-23.834 7.166v-11.333c6-.167 10.667-1.5 13.834-4 3.166-2.5 4.666-6.5 4.666-12V93.811c0-6.166 1.5-10.833 4.5-14.166 3-3.167 7.5-5.334 13.667-6.5v-1z"
        fillRule="nonzero"
      />
    </svg>
  );
}
